var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"expanded":_vm.tableMixin_expandedRows,"footer-props":{
    'items-per-page-options': [+_vm.pagination.rowsPerPage],
  },"headers":_vm.tableMixin_displayedHeaders,"items":_vm.rows,"loading":_vm.loading,"mobile-breakpoint":0,"page":_vm.pagination.page,"server-items-length":_vm.pagination.totalItems,"height":"319","fixed-header":"","disable-sort":"","dense":""},on:{"update:page":_vm.tableMixin_changePage},scopedSlots:_vm._u([{key:"item.order_part",fn:function(ref){
  var item = ref.item;
return [(
        item.order_part_process &&
          item.order_part_process.order_part &&
          item.order_part_process.order_part.order
      )?_c('a',{staticClass:"link",attrs:{"href":(_vm.ordersUrl + "?search=" + (item.order_part_process.order_part.order.order_no) + "&orderPartId=" + (item.order_part_process.order_part.id)),"target":"_blank"}},[_vm._v(_vm._s(item.order_part_process.order_part.order.order_no)+"."+_vm._s(item.order_part_process.order_part.order_part_no))]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }