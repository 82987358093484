var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTableLoader',{attrs:{"loading":_vm.loading}},[_c('v-data-table',{attrs:{"expanded":_vm.tableMixin_expandedRows,"footer-props":{
      'items-per-page-options': [_vm.pagination.rowsPerPage],
    },"headers":_vm.tableMixin_displayedHeaders,"items":_vm.displayedItems,"mobile-breakpoint":0,"page":_vm.pagination.page,"server-items-length":_vm.pagination.totalItems,"disable-sort":""},on:{"update:page":_vm.tableMixin_changePage,"click:row":_vm.tableMixin_onRowClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('BaseTableHeader',{attrs:{"title":_vm.$t('users'),"create-button":_vm.$t('create_user')},on:{"new-item":function($event){return _vm.$emit('new-item')}}}),_c('div',{staticClass:"d-flex flex-column flex-sm-row"},[_c('v-checkbox',{staticClass:"mx-3 mt-0",attrs:{"label":_vm.$t('show_active_users'),"value":"active"},on:{"change":function($event){return _vm.$emit('update-filter')}},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}}),_c('v-checkbox',{staticClass:"mx-3 mt-0",attrs:{"label":_vm.$t('show_suspended_users'),"value":"suspended"},on:{"change":function($event){return _vm.$emit('update-filter')}},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1)]},proxy:true},{key:"item.full_name",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{ 'grey--text': item.status === 'suspended' && _vm.$vuetify.breakpoint.smAndDown }},[_vm._v(" "+_vm._s(item.full_name)+" ")])]}},{key:"item.email",fn:function(ref){
    var item = ref.item;
return [_c('a',{staticClass:"link",attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])]}},{key:"item.phone",fn:function(ref){
    var item = ref.item;
return [_c('a',{staticClass:"link",attrs:{"href":("tel:" + (item.phone))}},[_vm._v(_vm._s(item.phone))])]}},{key:"item.address",fn:function(ref){
    var item = ref.item;
return [_c('a',{staticClass:"link",attrs:{"href":("https://maps.google.com/?q=" + (item.address)),"target":"_blank"}},[_vm._v(" "+_vm._s(item.address)+" ")])]}},{key:"item.translatedRole",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.translatedRole)),_c('br'),(item.role === 'worker' && item.processes.length)?_c('span',[_c('v-tooltip',{attrs:{"transition":"none","max-width":"35em","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({staticClass:"grey--text"},on),[_vm._v(" "+_vm._s(_vm.$t('visible_processes'))+" ("+_vm._s(item.processes.length)+") ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getProcessesString(item.processes))+" ")])])],1):_vm._e()]}},{key:"item.translatedStatus",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"caption persist-opacity",attrs:{"color":item.status === 'suspended' ? 'grey lighten-4' : 'green accent-1',"label":"","small":""}},[_vm._v(" "+_vm._s(item.translatedStatus)+" ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.getRowActions(item),"item":item}})]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.email",fn:function(){return [_c('a',{staticClass:"link",attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])]},proxy:true},{key:"item.phone",fn:function(){return [_c('a',{staticClass:"link",attrs:{"href":("tel:" + (item.phone))}},[_vm._v(_vm._s(item.phone))])]},proxy:true},{key:"item.address",fn:function(){return [_c('a',{staticClass:"link",attrs:{"href":("https://maps.google.com/?q=" + (item.address)),"target":"_blank"}},[_vm._v(" "+_vm._s(item.address)+" ")])]},proxy:true},{key:"item.translatedRole",fn:function(){return [_vm._v(" "+_vm._s(item.translatedRole)),_c('br'),(item.processes.length)?_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.$t('visible_processes'))+": "+_vm._s(_vm.getProcessesString(item.processes))+" ")]):_vm._e()]},proxy:true},{key:"item.translatedStatus",fn:function(){return [_c('v-chip',{staticClass:"caption persist-opacity",attrs:{"color":item.status === 'suspended' ? 'grey lighten-4' : 'green accent-1',"label":"","small":""}},[_vm._v(" "+_vm._s(item.translatedStatus)+" ")])]},proxy:true}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }