<template>
  <div class="page-wrapper">
    <UserTable
      :rows="userArray"
      :pagination="userPagination"
      :loading="isDataLoading"
      :filters="userFilterParams"
      @change-page="getPaginatedUsers"
      @edit="crudMixin_openForm('user', $event)"
      @impersonate="impersonateUser"
      @new-item="crudMixin_openForm('user', newUserTemplate)"
      @reset-password="resetUserPassword"
      @toggle-status="toggleUserStatus"
      @update-filter="getPaginatedUsers(1)"
      @get-qr-code="getQuickAuthQrCode"
      @show-unfinished-processes="selectedUserForUnfinishedProcesses = $event"
    />

    <v-dialog
      v-model="isUserFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      scrollable
      persistent
    >
      <UserForm
        :dialog="isUserFormOpen"
        :form-item="userFormItem"
        @cancel="isUserFormOpen = false"
        @create="userCreated"
        @update="crudMixin_updated('user', $event)"
      />
    </v-dialog>

    <canvas ref="qrCodeCanvas" class="d-none" />

    <UserUnfinishedProcessesDialog
      v-if="selectedUserForUnfinishedProcesses.id"
      :user="selectedUserForUnfinishedProcesses"
      @close="selectedUserForUnfinishedProcesses = {}"
    />
  </div>
</template>

<script>
import QRCode from 'qrcode';
import UserForm from '../components/forms/UserForm.vue';
import UserTable from '../components/tables/UserTable.vue';
import userService from '../api/user-service';
import crudMixin from '../mixins/crud-mixin';
import eventBus, {
  CONFIRM_DIALOG_CLOSED,
  OPEN_CONFIRM_DIALOG,
  OPEN_SNACKBAR,
} from '../util/event-bus';
import UserUnfinishedProcessesDialog from '@/components/UserUnfinishedProcessesDialog';

export default {
  name: 'Users',

  components: {
    UserUnfinishedProcessesDialog,
    UserForm,
    UserTable,
  },

  mixins: [crudMixin],

  data() {
    return {
      userArray: [],
      userPagination: {
        page: 1,
      },
      userFilterParams: {
        status: ['active'],
      },
      userFormItem: {},
      isUserFormOpen: false,
      isDataLoading: true,
      newUserTemplate: {
        person: {},
        status: 'active',
        role: 'worker',
        processes: [],
      },

      selectedUserForUnfinishedProcesses: {},
    };
  },

  created() {
    this.getPaginatedUsers(1);
  },

  methods: {
    getPaginatedUsers(pageNo) {
      this.crudMixin_getPage(userService.getPage, userService.model, pageNo, this.userFilterParams);
    },

    getQuickAuthQrCode(user) {
      userService.getQuickAuthTokenRoute(user).then(res => {
        const url =
          window.location.host +
          this.$router.resolve({
            name: 'quick-auth',
            params: { userId: user.id, hash: res.data },
          }).href;

        QRCode.toCanvas(this.$refs.qrCodeCanvas, url);
        const dataUrl = this.$refs.qrCodeCanvas.toDataURL('image/png');
        const newTab = window.open();
        console.log(url);
        if (newTab) {
          newTab.document.body.innerHTML = `<div style="width: 276px; text-align: center">
             <div><img src="${dataUrl}"></img></div>
             <span style="font-size: 14px">${user.person ? user.person.full_name : ''}</span>
          </div>`;
        } else {
          eventBus.$emit(OPEN_SNACKBAR, {
            text: this.$t('allow_browser_popups_and_retry'),
            timeout: 12000,
          });
        }
      });
    },

    async toggleUserStatus(user) {
      await this.crudMixin_update(userService.toggleStatus, userService.model, user);
      const newStatus = user.status === 'active' ? 'suspended' : 'active';
      this.crudMixin_updated('user', { ...user, status: newStatus });
    },

    resetUserPassword(user) {
      eventBus.$emit(OPEN_CONFIRM_DIALOG, {
        title: this.$t('confirm_password_reset'),
      });
      eventBus.$on(CONFIRM_DIALOG_CLOSED, confirmed => {
        if (confirmed) {
          userService.resetPassword(user).then(res => {
            if (user.role === 'worker') {
              eventBus.$emit(OPEN_CONFIRM_DIALOG, {
                title: `${this.$t('new_worker_pin')}: ${res.data.pin}`,
                cancelText: '',
                confirmText: 'OK',
              });
            } else {
              eventBus.$emit(OPEN_SNACKBAR, this.$t('password_was_reset'));
            }
          });
        }
        eventBus.$off(CONFIRM_DIALOG_CLOSED);
      });
    },

    impersonateUser(user) {
      this.$store.dispatch('impersonateUser', user.id);
    },

    userCreated(user) {
      this.crudMixin_created(userService.model, user);
      if (user.role === 'worker') {
        eventBus.$emit(OPEN_CONFIRM_DIALOG, {
          title: `${this.$t('worker_pin')}: ${user.pin}`,
          cancelText: '',
          confirmText: 'OK',
        });
      }
    },
  },
};
</script>
