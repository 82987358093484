<template>
  <v-dialog
    v-model="isOpen"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    scrollable
    @input="closeDialog"
  >
    <v-card v-if="user">
      <v-card-title v-if="user && user.person">
        {{ user.person.full_name }} {{ $t('unfinished_processes').toLowerCase() }}
      </v-card-title>

      <v-card-text class="px-0">
        <UserProcessTable
          :rows="processArray"
          :pagination="processPagination"
          :loading="
            $store.getters.loading[`get:api/users/${user.id}/unfinished-order-part-processes`]
          "
          @change-page="getUserActiveProcesses"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text color="primary" @click="closeDialog">
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import userService from '@/api/user-service';
import crudMixin from '@/mixins/crud-mixin';
import UserProcessTable from '@/components/tables/UserProcessTable';
import { sleep } from '@/util/sleep';

export default {
  name: 'UserUnfinishedProcessesDialog',

  components: { UserProcessTable },

  mixins: [crudMixin],

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      processArray: [],
      processPagination: {
        page: 1,
      },
      isOpen: false,
    };
  },

  created() {
    this.getUserActiveProcesses();
    this.isOpen = true;
  },

  methods: {
    getUserActiveProcesses(page = 1) {
      this.crudMixin_getPage(userService.getUserUnfinishedProcesses, 'process', page, this.user.id);
    },

    async closeDialog() {
      this.isOpen = false;
      await sleep(300);
      this.$emit('close');
    },
  },
};
</script>

<style scoped></style>
