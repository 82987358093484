<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="user.person.first_name"
              :error-messages="errors['person.first_name']"
              :label="formMixin_getRequiredFieldLabel($t('first_name'))"
              name="first_name"
              autofocus
              @blur="formMixin_clearErrors('person.first_name')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="user.person.last_name"
              :error-messages="errors['person.last_name']"
              :label="formMixin_getRequiredFieldLabel($t('last_name'))"
              name="last_name"
              @blur="formMixin_clearErrors('person.last_name')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="user.email"
              :error-messages="errors['email']"
              :label="formMixin_getRequiredFieldLabel($t('email'))"
              name="email"
              @blur="formMixin_clearErrors('email')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="user.person.phone"
              :error-messages="errors['person.phone']"
              :label="$t('phone')"
              name="phone"
              @blur="formMixin_clearErrors('phone')"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="user.person.address"
              :error-messages="errors['person.address']"
              :label="$t('address')"
              name="address"
              @blur="formMixin_clearErrors('person.address')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              v-model="user.role"
              :items="roles"
              :disabled="$isCurrentUser(user.id)"
              :error-messages="errors['role']"
              :label="$t('role')"
              @blur="formMixin_clearErrors('role')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="user.person.position"
              :error-messages="errors['position']"
              :label="$t('position')"
              @blur="formMixin_clearErrors('position')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              v-model="user.status"
              :items="statuses"
              :disabled="$isCurrentUser(user.id)"
              :error-messages="errors['status']"
              :label="$t('status')"
              @blur="formMixin_clearErrors('status')"
            />
          </v-col>

          <template v-if="user.role === 'worker'">
            <v-col cols="12">
              <ProcessSelectMenu
                :process-groups="processGroupArray"
                :hidden-processes="user.processes"
                nudge-bottom="-16"
                top
                @select="addProcess"
              />
            </v-col>

            <v-col cols="12">
              <v-chip
                v-for="process in user.processes"
                :key="process.id"
                class="mr-1 mb-1"
                small
                close
                @click:close="removeProcess(process)"
              >
                {{ process.title }}
              </v-chip>
            </v-col>

            <v-col cols="12">
              <BaseFormErrors :error-messages="errors.processes" />
            </v-col>
          </template>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!user.id" class="subtitle-2 ml-3"> * {{ $t('must_be_filled') }} </span>

        <v-spacer />

        <v-btn text color="primary" @click="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          color="primary"
          text
          type="submit"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import formMixin from '../../mixins/form-mixin';
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import userService from '../../api/user-service';
import processGroupService from '../../api/process-group-service';
import ProcessSelectMenu from '../ProcessSelectMenu';
import BaseFormErrors from '../base/BaseFormErrors';

export default {
  name: 'UserForm',

  components: { BaseFormErrors, ProcessSelectMenu },

  props: {
    formItem: Object,
  },

  mixins: [formMixin, crudMixin, dialogMixin],

  data() {
    return {
      errors: {},
      isRequestPending: false,
      processGroupArray: [],
      roles: [
        { value: 'admin', text: this.$t('roles.admin') },
        { value: 'worker', text: this.$t('roles.worker') },
      ],
      statuses: [
        { value: 'active', text: this.$t('statuses.active') },
        { value: 'suspended', text: this.$t('statuses.suspended') },
      ],
      user: {},
    };
  },

  created() {
    this.crudMixin_getAll(processGroupService.getAll, processGroupService.model, '');
  },

  computed: {
    formTitle() {
      return this.$t(this.user.id ? 'edit_user' : 'new_user');
    },
  },

  methods: {
    addProcess(process) {
      for (let i = 0; i < this.user.processes.length; i++) {
        if (this.user.processes[i].id === process.id) {
          return;
        }
      }
      this.user.processes.push(process);
      this.formMixin_clearErrors('processes');
    },

    removeProcess(process) {
      this.user.processes = this.user.processes.filter(p => p.id !== process.id);
      this.formMixin_clearErrors('processes');
    },

    onDialogOpen() {
      this.user = JSON.parse(JSON.stringify(this.formItem));
      this.errors = {};
    },

    async onSubmit() {
      const res = await this.crudMixin_createOrUpdate(userService, this.user);
      if (res?.status === 422) {
        this.formMixin_collectMultiSelectErrors('processes');
      }
    },
  },
};
</script>
