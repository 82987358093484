<template>
  <BaseTableLoader :loading="loading">
    <v-data-table
      :expanded="tableMixin_expandedRows"
      :footer-props="{
        'items-per-page-options': [pagination.rowsPerPage],
      }"
      :headers="tableMixin_displayedHeaders"
      :items="displayedItems"
      :mobile-breakpoint="0"
      :page="pagination.page"
      :server-items-length="pagination.totalItems"
      disable-sort
      @update:page="tableMixin_changePage"
      @click:row="tableMixin_onRowClick"
    >
      <template v-slot:top>
        <BaseTableHeader
          :title="$t('users')"
          :create-button="$t('create_user')"
          @new-item="$emit('new-item')"
        />

        <div class="d-flex flex-column flex-sm-row">
          <v-checkbox
            v-model="filters.status"
            :label="$t('show_active_users')"
            value="active"
            class="mx-3 mt-0"
            @change="$emit('update-filter')"
          />
          <v-checkbox
            v-model="filters.status"
            :label="$t('show_suspended_users')"
            value="suspended"
            class="mx-3 mt-0"
            @change="$emit('update-filter')"
          />
        </div>
      </template>

      <template v-slot:item.full_name="{ item }">
        <span
          :class="{ 'grey--text': item.status === 'suspended' && $vuetify.breakpoint.smAndDown }"
        >
          {{ item.full_name }}
        </span>
      </template>

      <template v-slot:item.email="{ item }">
        <a :href="`mailto:${item.email}`" class="link">{{ item.email }}</a>
      </template>

      <template v-slot:item.phone="{ item }">
        <a :href="`tel:${item.phone}`" class="link">{{ item.phone }}</a>
      </template>

      <template v-slot:item.address="{ item }">
        <a :href="`https://maps.google.com/?q=${item.address}`" class="link" target="_blank">
          {{ item.address }}
        </a>
      </template>

      <template v-slot:item.translatedRole="{ item }">
        {{ item.translatedRole }}<br />
        <span v-if="item.role === 'worker' && item.processes.length">
          <v-tooltip transition="none" max-width="35em" bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on" class="grey--text">
                {{ $t('visible_processes') }} ({{ item.processes.length }})
              </span>
            </template>
            <span>
              {{ getProcessesString(item.processes) }}
            </span>
          </v-tooltip>
        </span>
      </template>

      <template v-slot:item.translatedStatus="{ item }">
        <v-chip
          :color="item.status === 'suspended' ? 'grey lighten-4' : 'green accent-1'"
          class="caption persist-opacity"
          label
          small
        >
          {{ item.translatedStatus }}
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <BaseActionMenu :actions="getRowActions(item)" :item="item" />
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <BaseExpandedTableRow
          :colspan="tableMixin_displayedHeaders.length"
          :headers="tableMixin_hiddenHeaders"
          :item="item"
        >
          <template v-slot:item.email>
            <a :href="`mailto:${item.email}`" class="link">{{ item.email }}</a>
          </template>

          <template v-slot:item.phone>
            <a :href="`tel:${item.phone}`" class="link">{{ item.phone }}</a>
          </template>

          <template v-slot:item.address>
            <a :href="`https://maps.google.com/?q=${item.address}`" class="link" target="_blank">
              {{ item.address }}
            </a>
          </template>

          <template v-slot:item.translatedRole>
            {{ item.translatedRole }}<br />
            <span v-if="item.processes.length" class="grey--text">
              {{ $t('visible_processes') }}: {{ getProcessesString(item.processes) }}
            </span>
          </template>
          <template v-slot:item.translatedStatus>
            <v-chip
              :color="item.status === 'suspended' ? 'grey lighten-4' : 'green accent-1'"
              class="caption persist-opacity"
              label
              small
            >
              {{ item.translatedStatus }}
            </v-chip>
          </template>
        </BaseExpandedTableRow>
      </template>
    </v-data-table>
  </BaseTableLoader>
</template>

<script>
import tableMixin from '../../mixins/table-mixin';
import BaseActionMenu from '../base/BaseActionMenu';
import BaseTableHeader from '../base/BaseTableHeader';
import BaseExpandedTableRow from '../base/BaseExpandedTableRow';
import BaseTableLoader from '../base/BaseTableLoader';

export default {
  name: 'UserTable',

  components: {
    BaseTableLoader,
    BaseExpandedTableRow,
    BaseTableHeader,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    rows: Array,
    pagination: Object,
    loading: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      headers: [
        {
          text: this.$t('full_name'),
          value: 'full_name',
        },
        {
          text: this.$t('phone'),
          value: 'phone',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('email'),
          value: 'email',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('address'),
          value: 'address',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('role'),
          value: 'translatedRole',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('position'),
          value: 'position',
          hidden: 'mdAndDown',
        },
        {
          text: this.$t('status'),
          value: 'translatedStatus',
          hidden: 'smAndDown',
        },
        {
          value: 'actions',
        },
      ],
    };
  },

  computed: {
    displayedItems() {
      return this.rows.map(r => ({
        ...r,
        ...r.person,
        translatedRole: this.$t(`roles.${r.role}`),
        translatedStatus: this.$t(`statuses.${r.status}`),
      }));
    },
  },

  methods: {
    getProcessesString(processes) {
      if (!processes.length) {
        return '';
      }
      return processes.reduce((res, process) => `${res}${process.title}, `, '').slice(0, -2);
    },

    getRowActions(user) {
      const actions = [
        {
          callback: p => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: p => this.$emit('toggle-status', p),
          label: this.$t(user.status === 'active' ? 'suspend' : 'activate'),
          icon: user.status === 'active' ? 'mdi-cancel' : 'mdi-shield-check',
        },
        {
          callback: p => this.$emit('impersonate', p),
          label: this.$t('impersonate'),
          icon: 'mdi-account',
        },
        {
          callback: p => this.$emit('reset-password', p),
          label: this.$t('reset_password'),
          icon: 'mdi-lock-reset',
        },
      ];

      if (user.role === 'worker') {
        actions.unshift({
          callback: p => this.$emit('get-qr-code', p),
          label: this.$t('get_login_qr_code'),
          icon: 'mdi-qrcode',
        });
        actions.unshift({
          callback: p => this.$emit('show-unfinished-processes', p),
          label: this.$t('show_unfinished_processes'),
          icon: 'mdi-wrench',
        });
      }

      return this.$isCurrentUser(user.id) ? actions.slice(0, 1) : actions;
    },
  },
};
</script>
