<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :footer-props="{
      'items-per-page-options': [+pagination.rowsPerPage],
    }"
    :headers="tableMixin_displayedHeaders"
    :items="rows"
    :loading="loading"
    :mobile-breakpoint="0"
    :page="pagination.page"
    :server-items-length="pagination.totalItems"
    height="319"
    fixed-header
    disable-sort
    dense
    @update:page="tableMixin_changePage"
  >
    <template v-slot:item.order_part="{ item }">
      <a
        v-if="
          item.order_part_process &&
            item.order_part_process.order_part &&
            item.order_part_process.order_part.order
        "
        :href="
          `${ordersUrl}?search=${item.order_part_process.order_part.order.order_no}&orderPartId=${item.order_part_process.order_part.id}`
        "
        class="link"
        target="_blank"
        >{{ item.order_part_process.order_part.order.order_no }}.{{
          item.order_part_process.order_part.order_part_no
        }}</a
      >
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      />
    </template>
  </v-data-table>
</template>

<script>
import tableMixin from '@/mixins/table-mixin';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import crudMixin from '@/mixins/crud-mixin';

export default {
  name: 'UserProcessTable',

  components: {
    BaseExpandedTableRow,
  },

  mixins: [tableMixin, crudMixin],

  props: {
    rows: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('order_part'),
          value: 'order_part',
        },
      ];
    },

    ordersUrl() {
      return `${process.env.VUE_APP_PUBLIC_PATH}orders`;
    },
  },
};
</script>
